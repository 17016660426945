import { DEFAULT_AGE } from 'constants/values';
import { ActivityLevels, FitnessLevel } from 'constants/onboardingFlow';

import { OnboardingStore } from 'types/onboarding/onboardingStore';

import dayjs from 'dayjs';

export const SKIP_ONBOARDING_STATE: OnboardingStore = {
    gender: 'female',
    current_body: 'regular',
    target_bodytype: 'shapely',
    target_weight: 55,
    activityLevel: ActivityLevels.HIGHLY_ACTIVE,
    fitness_walking: '20-60',
    default_activity: 'hiking',
    age: DEFAULT_AGE,
    tall: 167,
    current_weight: 65,
    measureSystem: 'METRIC',
    onboardingScreensList: {},
    health: 'no',
    reasons: ['improve_physic'],
    special_activity: ['backpacking'],
    urlParams: null,
    occasion: 'reunion',
    occasion_date: dayjs().add(3, 'months'),
    fitnessLevel: FitnessLevel.Experienced,
    age_range: '40-45',
    crowded: 3,
    suitable_trails: 3,
    state: 'New York',
    push: 3,
    occasionResultConfig: {
        targetDate: dayjs().add(4, 'months'),
        weightList: [86, 83, 80, 77],
        monthsArray: ['Jan', 'Feb', 'Mar', 'Apr'],
    },
    focus: ['fat_burn'],
    hike: 'im_pro',
    trailDifficulty: 3,
    waterfall: 3,
    beforehand: 3,
    trailDistance: '0-3',
    injuries: ['none'],
    healthCondition: [''],
    stressLevel: 3,
    personType: '',
    feelings: [''],
    bmi_group: 'obese',
    walking: '20_60_mins',
    bucket: {},
    isDiscountScratchingFinished: false,
};
