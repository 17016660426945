export const startRoutes = [
    {
        path: 'StartPages/StartAge',
        route: 'start-age',
    },
    {
        path: 'StartPages/StartGender',
        route: 'start-gender',
    },
    {
        path: 'StartPages/StartGenderA',
        route: 'start-gender-a',
    },
    {
        path: 'StartPages/StartAgeA',
        route: 'start-age-a',
    },
    {
        path: 'StartPages/StartAgeB',
        route: 'start-age-b',
    },
    {
        path: 'StartPages/StartAgeBn',
        route: 'start-age-bn',
    },
    {
        path: 'StartPages/StartAgeC',
        route: 'start-age-c',
    },
    {
        path: 'StartPages/StartAgeC',
        route: 'start-age-f',
    },
    {
        path: 'StartPages/IndexPage',
        route: 'index',
    },
    {
        path: 'StartPages/StartAgeFull2',
        route: 'start-age-full2',
    },
    {
        path: 'StartPages/StartPageGeneral',
        route: 'start-page-general',
    },
    {
        path: 'StartPages/StartAgeFull3',
        route: 'start-age-full3',
    },
    {
        path: 'StartPages/StartAgeFull2Des',
        route: 'start-age-full2-des',
    },
    {
        path: 'StartPages/StartQuiz',
        route: 'start-quiz',
    },
    {
        path: 'StartPages/StartQuizB',
        route: 'start-quiz-b',
    },
    {
        path: 'StartPages/StartQuizBr',
        route: 'start-quiz-br',
    },
    {
        path: 'StartPages/StartAgeVertical',
        route: 'start-age-vertical',
    },
    {
        path: 'StartPages/StartAgeBg',
        route: 'start-age-bg',
    },
];
