export const SET_GENDER = 'SET_GENDER';
export const SET_CURRENT_BODY = 'SET_CURRENT_BODY';
export const SET_TARGET_BODY = 'SET_TARGET_BODY';
export const SET_TARGET_WEIGHT = 'SET_TARGET_WEIGHT';
export const SET_ACTIVITY_LVL = 'SET_ACTIVITY_LVL';
export const SET_CURRENT_WEIGHT = 'SET_CURRENT_WEIGHT';
export const SET_AGE = 'SET_AGE';
export const SET_TALL = 'SET_TALL';
export const SET_MEASURE_SYSTEM = 'SET_MEASURE_SYSTEM';
export const SET_ONBOARDING_SCREENS_LIST = 'SET_ONBOARDING_SCREENS_LIST';
export const INIT_AUTO_LOGIN = 'INIT_AUTO_LOGIN';
export const SET_URL_PARAMS = 'SET_URL_PARAMS';
export const SET_OCCASION = 'SET_OCCASION';
export const SET_OCCASION_DATE = 'SET_OCCASION_DATE';
export const SET_FITNESS_LVL = 'SET_FITNESS_LVL';
export const SET_AGE_RANGE = 'SET_AGE_RANGE';
export const SKIP_ONBOARDING = 'SKIP_ONBOARDING';
export const SET_SKIP_ONBOARDING_STATE = 'SET_SKIP_ONBOARDING_STATE';
export const SET_OCCASION_RESULT_CONFIG = 'SET_OCCASION_RESULT_CONFIG';
export const SET_FOCUS = 'SET_FOCUS';
export const SET_FITNESS_WALKING = 'SET_FITNESS_WALKING';
export const SET_DEFAULT_ACTIVITY = 'SET_DEFAULT_ACTIVITY';
export const SET_REASONS = 'SET_REASONS';
export const SET_SPECIAL_ACTIVITY = 'SET_SPECIAL_ACTIVITY';
export const SET_HEALTH = 'SET_HEALTH';
export const SET_CROWDED = 'SET_CROWDED';
export const SET_SUITABLE_TRAILS = 'SET_SUITABLE_TRAILS';
export const SET_PUSH = 'SET_PUSH';
export const SET_HIKE = 'SET_HIKE';
export const SET_TRAIL_DIFFICULTY = 'SET_TRAIL_DIFFICULTY';
export const SET_WATERFALL = 'SET_WATERFALL';
export const SET_BEFOREHAND = 'SET_BEFOREHAND';
export const SET_TRAIL_DISTANCE = 'SET_TRAIL_DISTANCE';
export const SET_INJURIES = 'SET_INJURIES';
export const SET_HEALTH_CONDITION = 'SET_HEALTH_CONDITION';
export const SET_STRESS_LEVEL = 'SET_STRESS_LEVEL';
export const SET_PERSON_TYPE = 'SET_PERSON_TYPE';
export const SET_FEELINGS = 'SET_FEELINGS';
export const SET_BMI_GROUP = 'SET_BMI_GROUP';
export const SET_STATE = 'SET_STATE';
export const SET_WALKING = 'SET_WALKING';
export const ADD_TO_BACKET = 'ADD_TO_BACKET';
export const SET_IS_DISCOUNT_SCRATCHING_FINISHED = 'SET_IS_DISCOUNT_SCRATCHING_FINISHED';
