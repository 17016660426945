export const onboardingRoutes = [
    {
        path: 'OnboardingPages/ObWelcome',
        route: 'ob-welcome',
    },
    {
        path: 'OnboardingPages/ObBenefits',
        route: 'ob-benefits',
    },
    {
        path: 'OnboardingPages/ObRisk',
        route: 'ob-risk',
    },
    {
        path: 'OnboardingPages/ObHealthFeedback',
        route: 'ob-health-feedback',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-health-feedback-a',
    },
    {
        path: 'OnboardingPages/ObSocialProof',
        route: 'ob-social-proof',
    },
    {
        path: 'OnboardingPages/ObHealthLoader',
        route: 'ob-health-loader',
    },
    {
        path: 'OnboardingPages/ObWalking',
        route: 'ob-fitness-walking',
    },
    {
        path: 'OnboardingPages/ObDefActivity',
        route: 'ob-default-activity',
    },
    {
        path: 'OnboardingPages/ObSmartwatch',
        route: 'ob-smartwatch',
    },
    {
        path: 'OnboardingPages/ObGender',
        route: 'ob-gender',
    },
    {
        path: 'OnboardingPages/ObReasons',
        route: 'ob-reasons',
    },
    {
        path: 'OnboardingPages/ObSpecialActivity',
        route: 'ob-special-activity',
    },
    {
        path: 'OnboardingPages/ObBackKneesIssues',
        route: 'ob-back-knees-issues',
    },
    {
        path: 'OnboardingPages/ObHealth',
        route: 'ob-health',
    },
    {
        path: 'OnboardingPages/ObMedication',
        route: 'ob-medication',
    },
    {
        path: 'OnboardingPages/ObHeight',
        route: 'ob-height',
    },
    {
        path: 'OnboardingPages/ObCurrentWeight',
        route: 'ob-weight',
    },
    {
        path: 'OnboardingPages/ObAge',
        route: 'ob-age',
    },
    {
        path: 'OnboardingPages/FeedbackSummary',
        route: 'ob-feedback-summary',
    },
    {
        path: 'OnboardingPages/CreatingA',
        route: 'creating',
    },
    {
        path: 'OnboardingPages/EmailA',
        route: 'email-a',
    },
    {
        path: 'OnboardingPages/Email',
        route: 'email',
    },
    {
        path: 'OnboardingPages/EmailConsent',
        route: 'email-consent',
    },
    {
        path: 'OnboardingPages/ObOccasion',
        route: 'ob-occasion-a',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObOccasion',
        route: 'ob-occasion-b',
    },
    {
        path: 'OnboardingPages/ObMedications',
        route: 'ob-medications',
    },
    {
        path: 'OnboardingPages/ObGoalsB',
        route: 'ob-goals-b',
    },
    {
        path: 'OnboardingPages/ObCurrentWeightDes',
        route: 'ob-weight-des',
    },
    {
        path: 'OnboardingPages/ObTargetWeightDes',
        route: 'ob-weight-target-des',
    },
    {
        path: 'OnboardingPages/ObSocialProofDes',
        route: 'ob-social-proof-des',
    },
    {
        path: 'OnboardingPages/ObFamiliarityFullDes',
        route: 'ob-familiarity-full-des',
    },
    {
        path: 'OnboardingPages/CurrentBodyADes',
        route: 'ob-current-body-type-a-des',
    },
    {
        path: 'OnboardingPages/TargetBodyADes',
        route: 'ob-target-body-type-a-des',
    },
    {
        path: 'OnboardingPages/ActivityLevelDes',
        route: 'ob-activity-level-des',
    },
    {
        path: 'OnboardingPages/ObWalkingDes',
        route: 'ob-walking-des',
    },
    {
        path: 'OnboardingPages/PushupsSquatsDes',
        route: 'ob-pushups-squats-des',
    },
    {
        path: 'OnboardingPages/PilatesLevelDes',
        route: 'ob-pilates-level-des',
    },
    {
        path: 'OnboardingPages/BendingKneesDes',
        route: 'ob-bending-knees-des',
    },
    {
        path: 'OnboardingPages/ObTagsADes',
        route: 'ob-tags-a-des',
    },
    {
        path: 'OnboardingPages/MealsFeelDes',
        route: 'ob-meals-feel-des',
    },
    {
        path: 'OnboardingPages/ObSleepDes',
        route: 'ob-sleep-des',
    },
    {
        path: 'OnboardingPages/ObWaterDes',
        route: 'ob-water-des',
    },
    {
        path: 'OnboardingPages/ObDietsDes',
        route: 'ob-diets-des',
    },
    {
        path: 'OnboardingPages/ObOccasionDes',
        route: 'ob-occasion-des',
    },
    {
        path: 'OnboardingPages/ObOccasionResultDes',
        route: 'ob-occasion-result-des',
    },
    {
        path: 'OnboardingPages/ObOccasionResultA',
        route: 'ob-occasion-result-a',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObOccasionResultB',
        route: 'ob-occasion-result-b',
    },
    {
        path: 'OnboardingPages/CreatingADes',
        route: 'creating-des',
    },
    {
        path: 'OnboardingPages/EmailAccountADes',
        route: 'email-account-a-des',
    },
    {
        path: 'OnboardingPages/EmailConsentFullDes',
        route: 'email-consent-full-des',
    },
    {
        path: 'OnboardingPages/YogaLevelDes',
        route: 'ob-yoga-level-des',
    },
    {
        path: 'OnboardingPages/ObHealthConditions',
        route: 'ob-health-conditions',
    },
    {
        path: 'OnboardingPages/ObBackIssueConfig',
        route: 'ob-back-issue-config',
    },
    {
        path: 'OnboardingPages/ObCalculatingGraph',
        route: 'ob-calculating-graph',
    },
    {
        path: 'OnboardingPages/ObTrustFeedback',
        route: 'ob-trust-feedback',
    },
    {
        path: 'OnboardingPages/ObWaterfall',
        route: 'ob-waterfall',
    },
    {
        path: 'OnboardingPages/ObCrowded',
        route: 'ob-crowded',
    },
    {
        path: 'OnboardingPages/ObSuitableTrails',
        route: 'ob-suitable-trails',
    },
    {
        path: 'OnboardingPages/ObBadWeather',
        route: 'ob-bad-weather',
    },
    {
        path: 'OnboardingPages/ObTrailDifficulty',
        route: 'ob-trail-difficulty',
    },
    {
        path: 'OnboardingPages/ObBeforehand',
        route: 'ob-beforehand',
    },
    {
        path: 'OnboardingPages/ObGetLost',
        route: 'ob-get-lost',
    },
    {
        path: 'OnboardingPages/ObWeekends',
        route: 'ob-weekends',
    },
    {
        path: 'OnboardingPages/ObPush',
        route: 'ob-push',
    },
    {
        path: 'OnboardingPages/ObBrain',
        route: 'ob-brain',
    },
    {
        path: 'OnboardingPages/ObStressLevel',
        route: 'ob-stress-level',
    },
    {
        path: 'OnboardingPages/ObReasonsA',
        route: 'ob-reasons-a',
    },
    {
        path: 'OnboardingPages/ObNature',
        route: 'ob-nature',
    },
    {
        path: 'OnboardingPages/ObHikingA',
        route: 'ob-hiking-a',
    },
    {
        path: 'OnboardingPages/ObTrailDistance',
        route: 'ob-trail-distance',
    },
    {
        path: 'OnboardingPages/ObPersonType',
        route: 'ob-person-type',
    },
    {
        path: 'OnboardingPages/ObFitnessLevelA',
        route: 'ob-fitness-level-a',
    },
    {
        path: 'OnboardingPages/ObTagsA',
        route: 'ob-tags-a',
    },
    {
        path: 'OnboardingPages/ObTypeHiking',
        route: 'ob-type-hiking',
    },
    {
        path: 'OnboardingPages/ObSpecialActivityA',
        route: 'ob-special-activity-a',
    },
    {
        path: 'OnboardingPages/ObFeelings',
        route: 'ob-feelings',
    },
    {
        path: 'OnboardingPages/ObChallenges',
        route: 'ob-challenges',
    },
    {
        path: 'OnboardingPages/ObInjuries',
        route: 'ob-injuries',
    },
    {
        path: 'OnboardingPages/ObSocialize',
        route: 'ob-socialize',
    },
    {
        path: 'OnboardingPages/ObBusy',
        route: 'ob-busy',
    },
    {
        path: 'OnboardingPages/ObInnerPower',
        route: 'ob-inner-power',
    },
    {
        path: 'OnboardingPages/WelcomeToFottur',
        route: 'welcome-to-fottur',
    },
    {
        path: 'OnboardingPages/ObHikingProfileSummary',
        route: 'ob-hiking-profile-summary',
    },
    {
        path: 'OnboardingPages/ObHealthProfileSummary',
        route: 'ob-health-profile-summary',
    },
    {
        path: 'OnboardingPages/ObClearGoal',
        route: 'ob-clear-goal',
    },
    {
        path: 'OnboardingPages/ObNatureFeedback',
        route: 'ob-nature-feedback',
    },
    {
        path: 'OnboardingPages/ObBenefitsA',
        route: 'ob-benefits-a',
    },
    {
        path: 'OnboardingPages/ObHikingProfile',
        route: 'ob-hiking-profile',
    },
    {
        path: 'OnboardingPages/ObGoodHands',
        route: 'ob-good-hands',
    },
    {
        path: 'OnboardingPages/ObHeathLoader',
        route: 'ob-heath-loader',
    },
    {
        path: 'OnboardingPages/ObInjuriesFeedback',
        route: 'ob-injuries-feedback',
    },
    {
        path: 'OnboardingPages/EmailA',
        route: 'email-a',
    },
    {
        path: 'OnboardingPages/ObCrowdedFeedback',
        route: 'ob-crowded-feedback',
    },
    {
        path: 'OnboardingPages/ObSuitableTrailsFeedback',
        route: 'ob-suitable-trails-feedback',
    },
    {
        path: 'OnboardingPages/ObPushFeedback',
        route: 'ob-push-feedback',
    },
    {
        path: 'OnboardingPages/ObPersonTypeFeedback',
        route: 'ob-person-type-feedback',
    },
    {
        path: 'OnboardingPages/ObBrainFeedback',
        route: 'ob-brain-feedback',
    },
    {
        path: 'OnboardingPages/CreatingA',
        route: 'ob-reviews',
    },
    {
        path: 'OnboardingPages/ObPushFeedback',
        route: 'ob-person-type-feedback',
    },
    {
        path: 'OnboardingPages/ObPushFeedback',
        route: 'ob-brain-feedback',
    },
    {
        path: 'OnboardingPages/ObPushFeedback',
        route: 'ob-reviews',
    },
    {
        path: 'OnboardingPages/ObPsychologicalProfile',
        route: 'ob-psychological-profile',
    },
    {
        path: 'OnboardingPages/ObHikingLoader',
        route: 'ob-hiking-loader',
    },
    {
        path: 'OnboardingPages/ObEmotions',
        route: 'ob-emotions',
    },
    {
        path: 'OnboardingPages/ObDriven',
        route: 'ob-driven',
    },
    {
        path: 'OnboardingPages/ObWalkingMeditation',
        route: 'ob-walking-meditation',
    },
    {
        path: 'OnboardingPages/ObStressReasons',
        route: 'ob-stress-reasons',
    },
    {
        path: 'OnboardingPages/ObHabits',
        route: 'ob-habits',
    },
    {
        path: 'OnboardingPages/ObProcrastination',
        route: 'ob-procrastination',
    },
    {
        path: 'OnboardingPages/ObSleepIssues',
        route: 'ob-sleep-issues',
    },
    {
        path: 'OnboardingPages/ObTraumaExperience',
        route: 'ob-trauma-experience',
    },
    {
        path: 'OnboardingPages/ObTraumaInfo',
        route: 'ob-trauma-info',
    },
    {
        path: 'OnboardingPages/ObTraumaChange',
        route: 'ob-trauma-change',
    },
    {
        path: 'OnboardingPages/ObTraumaProfile',
        route: 'ob-trauma-profile',
    },
    {
        path: 'OnboardingPages/ObStartAge',
        route: 'ob-start-age',
    },
    {
        path: 'OnboardingPages/ObCommitment',
        route: 'ob-commitment',
    },
    {
        path: 'OnboardingPages/ObReasonsMulti',
        route: 'ob-reasons-multi',
    },
    {
        path: 'OnboardingPages/ObReasonsMulti',
        route: 'ob-reasons-multi-a',
    },
    {
        path: 'OnboardingPages/ObChooseState',
        route: 'ob-choose-state',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObChooseState',
        route: 'ob-choose-state-b',
    },
    {
        path: 'OnboardingPages/ObTrailPersonalization1',
        route: 'ob-trail-personalization-1',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObTrailPersonalization1',
        route: 'ob-trail-personalization-1b',
    },
    {
        path: 'OnboardingPages/ObTrailPersonalization2',
        route: 'ob-trail-personalization-2',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObTrailPersonalization2',
        route: 'ob-trail-personalization-2b',
    },
    {
        path: 'OnboardingPages/ObTrailPersonalization3',
        route: 'ob-trail-personalization-3',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObTrailPersonalization3',
        route: 'ob-trail-personalization-3b',
    },
    {
        path: 'OnboardingPages/ObCommitmentSomatic',
        route: 'ob-commitment-somatic',
    },
    {
        path: 'OnboardingPages/ObSummaryFitLevel',
        route: 'ob-summary-fit-level',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObSummaryFitLevel',
        route: 'ob-summary-fit-level-b',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-reasons-somatic',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-reasons-somatic-a',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-stress',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-stress-a',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-exhausted',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-exhausted-a',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-stored-emotions',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-stored-emotions-a',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-relaxed',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-relaxed-a',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-body-tension',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-body-tension-a',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-headaches',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-headaches-a',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-somatic-hiking',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-somatic-hiking-a',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-balance',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-balance-a',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-best-shape',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-selfcare-time',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-selfcare-daily',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-motivation-a',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-emotional-traumas',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-emotional-traumas-a',
    },
    {
        path: 'OnboardingPages/DynamicMultiSelect',
        route: 'ob-trauma-impact',
    },
    {
        path: 'OnboardingPages/GreenTheme/DynamicMultiSelect',
        route: 'ob-trauma-impact-a',
    },
    {
        path: 'OnboardingPages/DynamicMultiSelect',
        route: 'ob-positive-emotions',
    },
    {
        path: 'OnboardingPages/GreenTheme/DynamicMultiSelect',
        route: 'ob-positive-emotions-a',
    },
    {
        path: 'OnboardingPages/DynamicMultiSelect',
        route: 'ob-tension-area',
    },
    {
        path: 'OnboardingPages/GreenTheme/DynamicMultiSelect',
        route: 'ob-tension-area-a',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-welcome-somatic',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-welcome-somatic-a',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-stress-response',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-stress-response-a',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-physical-response',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-physical-response-a',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-trauma-info-a',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-trauma-info-b',
    },
    {
        path: 'OnboardingPages/ObTraumaChange',
        route: 'ob-trauma-change-a',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObTraumaChange',
        route: 'ob-trauma-change-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObGender',
        route: 'ob-gender-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObHiking',
        route: 'ob-hiking-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObNature',
        route: 'ob-nature-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObWalking',
        route: 'ob-fitness-walking-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObSmartwatch',
        route: 'ob-smartwatch-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObReasons',
        route: 'ob-reasons-b',
    },
    {
        path: 'OnboardingPages/ObTrailDistance',
        route: 'ob-trail-distance-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObBackKneesIssues',
        route: 'ob-back-knees-issues-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObInjuries',
        route: 'ob-injuries-b',
    },
    {
        path: 'OnboardingPages/ObHealth',
        route: 'ob-health-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObPersonType',
        route: 'ob-person-type-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeelings',
        route: 'ob-feelings-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObChallenges',
        route: 'ob-challenges-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObWaterfall',
        route: 'ob-waterfall-b',
    },

    {
        path: 'OnboardingPages/GreenTheme/ObCrowded',
        route: 'ob-crowded-b',
    },

    {
        path: 'OnboardingPages/GreenTheme/ObSuitableTrails',
        route: 'ob-suitable-trails-b',
    },

    {
        path: 'OnboardingPages/GreenTheme/ObBeforehand',
        route: 'ob-beforehand-b',
    },

    {
        path: 'OnboardingPages/GreenTheme/ObGetLost',
        route: 'ob-get-lost-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObBadWeather',
        route: 'ob-bad-weather-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObTrailDifficulty',
        route: 'ob-trail-difficulty-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObWeekends',
        route: 'ob-weekends-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObBrain',
        route: 'ob-brain-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObStressLevel',
        route: 'ob-stress-level-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObSocialProof',
        route: 'ob-social-proof-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/Email',
        route: 'ob-email-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-welcome-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-benefits-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-hiking-profile-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-injuries-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-suitable-trails-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-risk-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-good-hands-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-brain-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObPersonTypeFeedback',
        route: 'ob-person-type-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObPushFeedback',
        route: 'ob-push-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObCrowdedFeedback',
        route: 'ob-crowded-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObSocialize',
        route: 'ob-socialize-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObBusy',
        route: 'ob-busy-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObInnerPower',
        route: 'ob-inner-power-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFitnessLevelB',
        route: 'ob-fitness-level-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/EmailConsent',
        route: 'email-consent-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObHikingProfileSummary',
        route: 'ob-hiking-profile-summary-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObTagsC',
        route: 'ob-tags-c',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObHeight',
        route: 'ob-height-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObCurrentWeight',
        route: 'ob-weight-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObTargetWeight',
        route: 'ob-weight-target-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObAge',
        route: 'ob-age-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObPsychologicalProfile',
        route: 'ob-psychological-profile-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObHealthProfileSummary',
        route: 'ob-health-profile-summary-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObCommitment',
        route: 'ob-commitment-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObNatureFeedback',
        route: 'ob-nature-feedback-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObStartAge',
        route: 'ob-start-age-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObPush',
        route: 'ob-push-b',
    },
    {
        path: 'OnboardingPages/GreenTheme/CreatingG',
        route: 'creating-g',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObHikingLoader',
        route: 'ob-hiking-loader-b',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-social-proof-br',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-welcome-br',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-hiking-a-br',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-nature-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObFitnessLevelA',
        route: 'ob-fitness-level-a-br',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-fitness-walking-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObBenefitsA',
        route: 'ob-benefits-a-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObSmartwatch',
        route: 'ob-smartwatch-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObHikingProfileSummary',
        route: 'ob-hiking-profile-summary-br',
    },
    {
        path: 'OnboardingPages/ObReasonsA',
        route: 'ob-reasons-a-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObTagsA',
        route: 'ob-tags-a-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObNatureFeedback',
        route: 'ob-nature-feedback-br',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-hiking-profile-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObWaterfall',
        route: 'ob-waterfall-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObCrowded',
        route: 'ob-crowded-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObCrowdedFeedback',
        route: 'ob-crowded-feedback-br',
    },
    {
        path: 'OnboardingPages/DynamicScoring',
        route: 'ob-suitable-trails-br',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-suitable-trails-feedback-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObBeforehand',
        route: 'ob-beforehand-br',
    },
    {
        path: 'OnboardingPages/DynamicScoring',
        route: 'ob-get-lost-br',
    },
    {
        path: 'OnboardingPages/DynamicScoring',
        route: 'ob-bad-weather-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObTrailDifficulty',
        route: 'ob-trail-difficulty-br',
    },
    {
        path: 'OnboardingPages/ObTrailDistance',
        route: 'ob-trail-distance-br',
    },
    {
        path: 'OnboardingPages/DynamicScoring',
        route: 'ob-weekends-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObTrailPersonalization1',
        route: 'ob-trail-personalization-1-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObBackKneesIssues',
        route: 'ob-back-knees-issues-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObInjuries',
        route: 'ob-injuries-br',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-injuries-feedback-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObHealth',
        route: 'ob-health-br',
    },
    {
        path: 'OnboardingPages/ObHeight',
        route: 'ob-height-br',
    },
    {
        path: 'OnboardingPages/ObCurrentWeight',
        route: 'ob-weight-br',
    },
    {
        path: 'OnboardingPages/ObAge',
        route: 'ob-age-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObHealthProfileSummary',
        route: 'ob-health-profile-summary-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObTrailPersonalization2',
        route: 'ob-trail-personalization-2-br',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-risk-br',
    },
    {
        path: 'OnboardingPages/ObPersonType',
        route: 'ob-person-type-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObPersonTypeFeedback',
        route: 'ob-person-type-feedback-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObPush',
        route: 'ob-push-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObPushFeedback',
        route: 'ob-push-feedback-br',
    },
    {
        path: 'OnboardingPages/DynamicScoring',
        route: 'ob-brain-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObStressLevel',
        route: 'ob-stress-level-br',
    },
    {
        path: 'OnboardingPages/DynamicMultiSelect',
        route: 'ob-feelings-br',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-socialize-br',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-busy-br',
    },
    {
        path: 'OnboardingPages/DynamicSingleSelect',
        route: 'ob-inner-power-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObPsychologicalProfile',
        route: 'ob-psychological-profile-br',
    },
    {
        path: 'OnboardingPages/DynamicMultiSelect',
        route: 'ob-challenges-br',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-good-hands-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/EmailA',
        route: 'email-a-br',
    },
    {
        path: 'OnboardingPages/EmailConsent',
        route: 'email-consent-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObTrailPersonalization3',
        route: 'ob-trail-personalization-3-br',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-brain-feedback-br',
    },
    {
        path: 'OnboardingPages/ObSale',
        route: 'ob-sale',
    },
    {
        path: 'OnboardingPages/ObGender',
        route: 'ob-gender-br',
    },
    {
        path: 'OnboardingPages/ObStartAge',
        route: 'ob-start-age-b-br',
    },
    {
        path: 'OnboardingPages/ObHikingLoader',
        route: 'ob-hiking-loader-br',
    },
    {
        path: 'OnboardingPages/CreatingA',
        route: 'creating-br',
    },
    {
        path: 'OnboardingPages/brasilFunnel/ObCrowded',
        route: 'ob-crowded-br',
    },
    {
        path: 'OnboardingPages/ObScratch',
        route: 'ob-scratch',
    },
    {
        path: 'OnboardingPages/ObImproveSleep',
        route: 'ob-improve-sleep',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObFeedbackSimple',
        route: 'ob-sleep-feedback',
    },
    {
        path: 'OnboardingPages/DynamicFeedback',
        route: 'ob-create-account',
    },
    {
        path: 'OnboardingPages/GreenTheme/ObPsychologicalB',
        route: 'ob-psychological-b',
    },
];

// Oleh: Start from here
// ob-weight-des
// ob-weight-target-des
// ob-social-proof-des
// ob-familiarity-full-des
// ob-current-body-type-a-des
// ob-target-body-type-a-des
// ob-activity-level-des
// ob-walking-des
// ob-pushups-squats-des
// ob-pilates-level-des
// ob-bending-knees-des
// ob-tags-a-des
// ob-meals-feel-des
// ob-sleep-des
// ob-water-des
// ob-diets-des
// ob-occasion-des
// ob-occasion-result-des
// ob-occasion-result-a
// creating-des
// email-account-a-des
// email-consent-full-des
// ob-yoga-level-des
// ob-health-conditions
// ob-back-issue-config
// ob-calculating-graph
// ob-trust-feedback
// ob-tags-a
// ob-reviews
