import { Provider, uaParser } from 'wikr-core-analytics';
import Core from 'testania';
import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { selectTestaniaName } from 'redux/testania/selectors';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import config from 'config';

import api from 'apiSingleton';

import { selectCountry } from 'redux/user/selectors';
import {
    COMPLETE_REGISTRATION,
    GET_DEEP_LINK,
    SET_COUNTRY,
    SIGN_UP,
    UPDATE_INFO,
    UPDATE_MAILING_PREFERENCES,
} from 'redux/user/actionTypes';
import {
    disableIsLoading,
    enableIsLoading,
    getDeepLinksSuccess,
    setIsAutologinComplete,
    setIsPaid,
    setToken,
    setUserEmail,
    setUserId,
    signUp,
    signUpFail,
    signUpSuccess,
    updateMailingPreferences,
    updateUserInfo,
    updateUserInfoFail,
} from 'redux/user/actions';
import { setCurrency as setCurrencyToStore } from 'redux/payment/actions';
import { selectAge, selectUrlParams } from 'redux/onboadring/selectors';
import { INIT_AUTO_LOGIN } from 'redux/onboadring/actionTypes';
import { setCurrentWeight, setGender, setTall, setTargetWeight } from 'redux/onboadring/actions';

import { CABINET_PATH_REGEX } from 'constants/regex';
import { PAYMENT_DEFAULT_CURRENCY } from 'constants/payments/currency';
import { LTV_COEFFICIENT_KEY } from 'constants/ltv';
import { DEFAULT_LANGUAGE } from 'constants/localization';
import { COUNTRIES_CURRENCY_LIST } from 'constants/countriesList';

import sentry from 'services/Sentry/SentryInstance';
import { sendGTMLeadEvent } from 'services/Analytics/trackers/GTMtrakers';
import {
    sendAnalyticButtonSelectEventLabel,
    sendAnalyticLead,
    sendAnalyticRegistration,
    sendAnalyticSignUp,
    trackEventFixed,
} from 'services/Analytics';

import { getParamFromUrl, isEmpty } from 'helpers/utils';
import { getCurrentLocalization, getLocalizationFromAvailable } from 'helpers/localization';

import { DeepLinkData, UserInfoResponse } from 'types/user/userApiInterface';
import { GetUserResponse } from 'types/user/getUser';
import { IGender } from 'types/commonInterfaces';

const UAParser = new uaParser();

export function* signUpSaga({
    payload: { payload, setError, toNextPage, sendAnalyticsOnSuccessSubmit },
}: ReturnType<typeof signUp>) {
    try {
        yield put(enableIsLoading());
        const abTestName: string = yield select(selectTestaniaName);

        const age: number = yield select(selectAge);
        const urlParams: Record<string, string> = yield select(selectUrlParams);
        const country: string = yield select(selectCountry);
        const userAgent = window.navigator.userAgent;

        const UAParserData = UAParser.setUA(userAgent);

        let signUpData = {
            ...payload,
            country,
            initial_funnel_data: {
                os_name: UAParserData.getOS().name,
                os_version: UAParserData.getOS().version,
                device_type: UAParserData.getDevice().type || 'desktop',
                device: UAParserData.getDevice().model,
                screen_width: window.screen.width,
                screen_height: window.screen.height,
                // url: window.location.href,
                ab_test_name: abTestName,
            },
        };

        if (!payload.target_weight || !payload.height || !payload.weight) {
            signUpData = {
                ...signUpData,
            };
        }

        const data: UserInfoResponse = yield api.user.signUp(signUpData);

        const { token, id, email, weight, height, gender } = data;

        sentry.setUser({ user_id: id, email });

        if (isEmpty(token)) {
            yield call(setError, 'basics.appError.withSupport');

            return;
        }

        Provider.setUserId(id);
        Provider.setUserEmail(email);

        yield all([put(setToken(token)), put(setUserId(id)), put(signUpSuccess(data)), put(setUserEmail(email))]);

        sendAnalyticLead();
        sendAnalyticRegistration();
        sendAnalyticSignUp();
        sendAnalyticsOnSuccessSubmit?.();
        sendGTMLeadEvent();

        yield put(disableIsLoading());

        yield call(toNextPage);

        Core.calculateLTVCoefficient({
            age: Number(age),
            weight,
            height,
            gender: gender as IGender,
            utm_source: urlParams?.utm_source || 'other',
        })
            .then((coefficient) => {
                localStorage.setItem(LTV_COEFFICIENT_KEY, coefficient);
            })
            .catch((error) => {
                sentry.logError(error, SENTRY_APP, ERROR_LEVELS.ERROR);
            });
    } catch (e) {
        console.error('Api ERROR', e);
        yield put(signUpFail(e));
        yield call(setError, e.error || 'basics.appError.withSupport');
    }
}

export function* updateUserInfoSaga({ payload }: ReturnType<typeof updateUserInfo>) {
    const { data, toNextPage, fieldError } = payload;

    yield put(enableIsLoading());

    try {
        const { email, id: user_id }: UserInfoResponse = yield api.user.completeRegistration(data);

        sentry.setUser({ user_id, email });

        yield put(setUserEmail(email));

        yield put(disableIsLoading());

        sendAnalyticButtonSelectEventLabel('registration_page');

        yield call(toNextPage);
    } catch ({ error }) {
        yield put(updateUserInfoFail(error));
        yield call(fieldError, error);
    }
}

export function* getDeepLinksSaga() {
    yield put(enableIsLoading());

    try {
        const link: DeepLinkData = yield api.user.deepLinks();

        trackEventFixed({ eventData: 'deeplink__load', data: { link } });
        yield put(getDeepLinksSuccess(link));
    } catch (error) {
        trackEventFixed({ eventData: 'deeplink__error', data: { error } });
        yield put(disableIsLoading());
        console.error('Api ERROR', error);
    }
}

export function* setUserProfileFromEmail() {
    const token = getParamFromUrl('token');

    if (!token) {
        yield put(setIsAutologinComplete(true));

        return;
    }

    try {
        const languageFromURL = getCurrentLocalization();

        const language = getLocalizationFromAvailable();

        localStorage.setItem('language', language || languageFromURL || DEFAULT_LANGUAGE);

        yield put(setToken(token));

        const {
            email,
            id,
            gender,
            is_paid,
            height,
            units,
            weight,
            target_weight,
            is_password,
        }: GetUserResponse = yield api.user.getUser();

        if (is_paid && is_password && config.USER_CABINET_URL.match(CABINET_PATH_REGEX)) {
            localStorage.clear();
            yield put({ type: 'RESET_STATE' });

            window.location.href = `${config.USER_CABINET_URL}main/?token=${token}`;
        } else {
            Provider.setUserId(id).then();
            Provider.setUserEmail(email);

            yield all([
                put(setUserEmail(email)),
                put(setIsAutologinComplete(true)),
                put(setGender((gender as IGender) || 'female')),
                put(setIsPaid(is_paid)),
                put(setTall({ value: height, unit: units })),
                put(setCurrentWeight({ value: weight, unit: units })),
                put(setTargetWeight({ value: target_weight, unit: units })),
            ]);
        }
    } catch (e) {
        console.error(e);
        yield put(setIsAutologinComplete(true));
    }
}

export function* setCurrency() {
    const userCountry: string = yield select(selectCountry);

    const filteredCountry = COUNTRIES_CURRENCY_LIST.filter(({ code }) => code === userCountry);

    if (userCountry) {
        // check if country included to config list
        if (filteredCountry.length) {
            const { currency } = filteredCountry.find((item) => item.currency) ?? {
                currency: PAYMENT_DEFAULT_CURRENCY,
            };

            yield put(setCurrencyToStore(currency));
        }
    }

    if (!userCountry || !filteredCountry.length) {
        yield put(setCurrencyToStore(PAYMENT_DEFAULT_CURRENCY));
    }
}

export function* updateMailingPreferencesSaga({ payload }: ReturnType<typeof updateMailingPreferences>) {
    try {
        yield put(enableIsLoading());

        const { data, nextPage } = payload;

        yield api.user.update(data);

        yield put(disableIsLoading());

        yield call(nextPage);
    } catch (e) {
        yield put(disableIsLoading());
        console.error(e);
    }
}

export const userSagas = [
    takeLatest(SIGN_UP, signUpSaga),
    takeLatest(UPDATE_INFO, updateUserInfoSaga),
    takeLatest(COMPLETE_REGISTRATION, updateUserInfoSaga),
    takeLatest(GET_DEEP_LINK, getDeepLinksSaga),
    takeLatest(INIT_AUTO_LOGIN, setUserProfileFromEmail),
    takeLatest(SET_COUNTRY, setCurrency),
    takeLatest(UPDATE_MAILING_PREFERENCES, updateMailingPreferencesSaga),
];
